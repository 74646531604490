<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss w-full">
    <div slot="no-body">
      <div class="justify-center items-center w-full">
        <div class="d-theme-dark-bg w-full" style="border-radius: 15px">
          <div class="p-5">
            <div class="logo">
              <img width="200" height="150" class="TextLogo" alt="" src="@/assets/images/logo.png" />
            </div>
            <div style="color:black">
              <p
                style='margin-top:0.5in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                <strong style="">What are website&rsquo; standard terms and conditions?&nbsp;</strong>
              </p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                The terms and conditions for this website have been articulated in the form of a list of rules created
                with an intention of setting a basis under which the business relationship can be established and for the
                purpose of regulating the website’s repetitive actions. &nbsp;</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                <strong>1. Intellectual Property Rights.&nbsp;</strong>
              </p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                Except for the content which you have voluntarily chosen to include in the website Medical Tourism
                Business under the stipulated rules, the licensor has full ownership of all the materials and intellectual
                property contained in the website, and every right has been reserved. All users have only been given
                limited license for the content dependent on the terms restrictions, for viewing material inside the
                website. Panda Tip: If and where the content in the website has been designed for viewership, then the
                user can pick it up from the last point above. Having said that, you will probably be required to offer
                more with respect to descriptive language concerning what the user is allowed to use our website for.</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                <strong>2. Restrictions.</strong>
              </p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                &nbsp; Website users are categorically and particularly delimited from:</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                &nbsp;1) Publication of any of the materials in this website on any media&nbsp;</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                2) Commercializing, sublicensing, or selling any material from this website&nbsp;</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                3) Utilizing the website in such a way that might damage it&nbsp;</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                4) Showing and/or performing any of the website material in the public</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.4in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                Registration on this website is prohibited for users less than 18 years old. Some of the areas in this
                website Medical Tourism Business have been
                restricted from users and further restrictions may be imposed on accessing other website areas at the
                discretion of the website owner. Any password
                and user identification owned for accessing this website is confidential and such information must be held
                in confidence.</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                <strong>
                  3. Your Content&nbsp;</strong>
              </p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                In accordance with the terms and conditions of this website, the content for the user means any video,
                audio, images, text, or any other materials
                the users opt to display from the website. With regard to the content displayed by users, the user grants
                the owner a worldwide, non-exclusive,
                royalty-free, irrevocable, sub-licensable license for the use, publishing, adaptation, translation,
                reproduction, and distribution of such content
                to any media.&nbsp;</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                The content displayed by users has to be their own and should never trespass the rights of any third
                parties. Medical Tourism Business holds the
                rights to delete any user content from the website at their discretion without giving notice, and for
                their own reasons.</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                <strong>5. No warranties.&nbsp;</strong>
              </p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                The website has been provided in its current form, with all the faults and there is no implied or express
                warranty representation from Medical Tourism Business in any form in relation to the website or contained
                materials. In addition, no information contained on the website should be taken to mean that advice or
                consultancy is provided to the user.</p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0pt;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                <strong>&nbsp;4. Limitation of liability.</strong>
              </p>
              <p
                style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0.2in;line-height:107%;font-size:15px;font-family:"Calibri","sans-serif";'>
                &nbsp; Under no circumstances shall the website Medical Tourism Business or any of the employees,
                directors and officers be held liable to the website user for any situation emanating from the use of the
                website or from the legal contracts sampled in any way, whether or not such a liability falls under tort,
                the contract or any other situation. Medical Tourism Business as well as the directors, employees, and
                officers will not be held accountable for their indirect, special or consequential liability emanating
                from anything associated with the website’s use.&rsquo;s use.&nbsp;</p>
            </div>
            <div style="text-align: center">
              <vs-button style="width: 60%; margin-top: 2rem" @click="$router.go(-1)">{{ $t("back") }}</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() { },
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}

.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}

.P-Text {
  color: #2753D9;
}
</style>


